<template>
  <v-card class="teros-elevation">
    <v-card-title class="px-5 mb-3 text-uppercase v-title-cadastro">
      Meu Perfil
    </v-card-title>
    <v-card-text class="px-5 v-form-cadastro">
      <v-row>
        <v-col cols="9">
          <v-row>
            <v-col cols="12">
              <input-v
                v-model="usuario.nome"
                :valid.sync="fields.nome.valid"
                :label="fields.nome"
                :editable="!isSend"
              ></input-v>
            </v-col>
            <v-col cols="6">
              <input-v
                v-model="usuario.usuario"
                :valid.sync="fields.usuario.valid"
                :label="fields.usuario"
                :editable="!(isSend || isClient)"
              ></input-v>
            </v-col>
            <v-col cols="6">
              <input-v
                v-model="usuario.email"
                :valid.sync="fields.email.valid"
                :label="fields.email"
                :editable="!isSend"
              ></input-v>
            </v-col>
            <v-col cols="12">
              <v-alert dense type="info" v-if="isClient">
                Para alterar o usuário, entre em contato com a Galapos.
              </v-alert>
              <v-alert dense type="warning" v-else>
                Cuidado! Ao alterar o Usuário para algo diferente do e-mail, o
                usuário perde o acesso por Single Sign-On (SSO).
              </v-alert>
            </v-col>
          </v-row>
          <v-row class="mt-5">
            <v-col cols="12" class="v-title-cadastro text-uppercase">
              Redefinir senha
            </v-col>
            <v-col cols="6">
              <input-v
                v-model="usuario.senhaAtual"
                :valid.sync="fields.senhaAtual.valid"
                :label="fields.senhaAtual"
                :editable="!isSend"
              ></input-v>
              <input-v
                v-model="usuario.senha"
                :valid.sync="fields.senha.valid"
                :label="fields.senha"
                :editable="!isSend"
              ></input-v>
              <input-v
                v-model="confirmaSenha"
                :valid.sync="fields.confirmaSenha.valid"
                :label="fields.confirmaSenha"
                :editable="!isSend"
              ></input-v>
            </v-col>
            <v-col v-if="usuario.usuario" cols="6" class="requerimentos">
              <div>
                <b>Uma senha forte deve conter:</b>
              </div>

              <div :class="{ 'requerimento-okay': minChars }">
                <v-icon v-if="minChars">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
                Mínimo de 8 caracteres
              </div>

              <div :class="{ 'requerimento-okay': minUppercase }">
                <v-icon v-if="minUppercase">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
                Mínimo 1 letra maiúscula
              </div>

              <div :class="{ 'requerimento-okay': minLowercase }">
                <v-icon v-if="minLowercase">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
                Mínimo 1 letra minúscula
              </div>

              <div :class="{ 'requerimento-okay': minDigit }">
                <v-icon v-if="minDigit">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
                Mínimo 1 número
              </div>

              <div :class="{ 'requerimento-okay': minSpecial }">
                <v-icon v-if="minSpecial">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
                Mínimo 1 especial dentre $!?{}()*&@#
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-file-input
            v-model="usuario.foto"
            v-show="false"
            accept="image/png, image/jpeg, image/webp"
            ref="file"
          ></v-file-input>
          <v-avatar size="105" class="d-block mx-auto">
            <img :src="previewUserPhoto" alt="Foto do usuário" />
          </v-avatar>
          <v-btn class="d-block mt-3 mx-auto" @click="$refs.file.$refs.input.click()" text>
            <v-icon left small>$uploadfoto</v-icon> Escolher foto
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="px-5 pb-4 pt-0">
      <v-spacer></v-spacer>
      <v-btn
        dark
        depressed
        class="px-4"
        color="secondary"
        @click="abrirTermosDeUso()"
      >
        Termos de uso
      </v-btn>
      <v-btn
        dark
        depressed
        class="px-4"
        color="primary"
        :disabled="disabledSalve"
        @click="doSave()"
      >
        Salvar
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="errorNotification" color="error" text outlined>
      <v-icon color="error" left>mdi-alert-circle-outline</v-icon>
      {{ errorMessage }}
    </v-snackbar>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  components: {
    "input-v": () => import("@/components/input-v.vue"),
  },
  computed: {
    previewUserPhoto: function () {
      const { foto } = this.usuario;

      if (foto instanceof File) {
        return URL.createObjectURL(foto);
      }

      return typeof foto === 'string' ? foto : '/img/noimg.jpg';
    },
    isClient: function () {
      return this.getClient().isClient;
    },
    sessionUser: function () {
      return this.$store.getters.jwtData.data;
    },
    senhaDigitada: function () {
      return !!this.usuario.senha && this.usuario.senha.length >= 1;
    },
    minChars: function () {
      return this.confirmaSenha && this.usuario.senha.length >= 8;
    },
    minUppercase: function () {
      return (
        this.confirmaSenha &&
        this.usuario.senha.replace(/[^A-Z]/g, "").length >= 1
      );
    },
    minLowercase: function () {
      return (
        this.confirmaSenha &&
        this.usuario.senha.replace(/[^a-z]/g, "").length >= 1
      );
    },
    minDigit: function () {
      return (
        this.confirmaSenha && this.usuario.senha.replace(/\D/g, "").length >= 1
      );
    },
    minSpecial: function () {
      return this.confirmaSenha && /_|[^\w\s]/g.test(this.usuario.senha);
    },
    bothEqual: function () {
      return this.confirmaSenha && this.confirmaSenha === this.usuario.senha;
    },
    disabledSalve: function () {
      return (
        (this.usuario.senha || this.usuario.senhaAtual || this.confirmaSenha) &&
        (!this.minChars ||
          !this.minUppercase ||
          !this.minLowercase ||
          !this.minDigit ||
          !this.minSpecial ||
          !this.bothEqual)
      );
    },
  },
  created: function () {
    this.doLoad();
  },
  data: function () {
    return {
      isSend: false,
      confirmaSenha: null,
      errorNotification: false,
      errorMessage: "",
      usuario: {
        nome: null,
        email: null,
        usuario: null,
        senha: null,
        senhaAtual: null,
        foto: null,
      },
      fields: {
        nome: {
          name: "Nome",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
        },
        email: {
          name: "Email",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
        },
        usuario: {
          name: "Usuário",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
        },
        senhaAtual: {
          name: "Senha Atual",
          type: this.$fieldTypes.PASSWORD,
          rules: [
            {
              rule: "requiredIf",
              params: {
                condition: () => this.usuario.senha && this.usuario.senha != "",
              },
            },
          ],
        },
        senha: {
          name: "Nova Senha",
          type: this.$fieldTypes.PASSWORD,
        },
        confirmaSenha: {
          name: "Confirmar Nova Senha",
          type: this.$fieldTypes.PASSWORD,
          rules: [
            {
              rule: "equal",
              params: {
                value: () => this.usuario.senha,
                name: "Nova Senha",
              },
            },
          ],
        },
        foto: {
          name: "Foto",
          type: this.$fieldTypes.AVATAR,
        },
      },
    };
  },
  methods: {
    ...mapActions(['getUserData']),
    abrirTermosDeUso: function () {
      const { href } = this.$router.resolve({ name: "termos-de-uso" });
      window.open(href, '_blank');
    },
    doLoad: function () {
      this.getUserData().then((response) => {
        this.usuario = response;
      });
    },
    doSave: async function () {
      let data = new FormData();
      const oReq = new XMLHttpRequest();
      const API_URL = process.env.VUE_APP_API_URL;
      const loadingProcessKey = 'meu-perfil-save';
      this.$root.$emit("addLoadingProcess", loadingProcessKey);
      this.isSend = true;
      
      Object.keys(this.fields).forEach((key) => !!this.usuario[key] && data.append(key, this.usuario[key]));

      const promise = new Promise((resolve, reject) => {
        oReq.onreadystatechange = () => {
          if (oReq.readyState == 4 && oReq.status == 200) {
            resolve(oReq.response);
          } else if (oReq?.response?.error) {
            reject(oReq.response);
          }

          this.$root.$emit("removeLoadingProcess", loadingProcessKey);
        };
      });

      oReq.open("POST", `${API_URL}/v1/authorization/me`, true);
      oReq.responseType = "json";
      const credentials = this.getCredentials();

      if (credentials) {
        oReq.setRequestHeader("X-Auth-Token", credentials);
      }

      oReq.send(data);
      const response = await promise;

      if (response.error) {
        this.errorMessage = response.error;
        this.errorNotification = true;
      } else {
        this.showAlert("Sucesso", "Seus dados foram atualizados!", () => {
          this.getUserData(true);
          this.$router.push({ name: "homepage" });
        });
      }

      this.isSend = false;
      return response;
    },
  },
};
</script>

<style lang="scss" scoped>
.requerimentos {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  justify-content: center;

  .requerimento-okay,
  .requerimento-okay .v-icon {
    color: #58ad58;
  }
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(#1c1c44, 0.7) !important;
}
</style>
